<template>
	<v-container fluid fill-height>
		<v-row align="center" justify="center" no-gutters>
			<v-col cols="12" sm="11">
				<base-heading :title="title">
					<base-btn v-if="showMainReport" dark color="green darken-1" @click="mainReportView()" icon="mdi-file-chart" btnText="Reporte"></base-btn>
        </base-heading>
			</v-col>
			<v-col cols="12" sm="1" align="center">
        <base-btn v-if="step === 1" color="grey darken-3" text @click="goBack" icon="mdi-arrow-left" btnText="Atras"></base-btn>
				<base-btn v-else-if="step === 2" color="grey darken-3" text @click="close" icon="mdi-arrow-left" btnText="Atras"></base-btn>
      </v-col>
		</v-row>

		<v-row>
			<v-col cols="12">
				<v-window v-model="step" touchless>
					<v-window-item :value="1">

						<v-expansion-panels
							v-model="panel"
							multiple
						>
							<v-expansion-panel>
								<v-expansion-panel-header class="uppercase">Movimientos realizados</v-expansion-panel-header>
								<v-expansion-panel-content>
									<base-data-table 
										v-model="sortBy"
										desc
										:headers="headers" 
										:items="inventoryMovements" 
										@loadDataTable="toList(2)"
										@newItem="step++"
									>
										<template v-slot:item.options="{ item }">
											<base-icon-btn color="info darken-1" @click="viewItem(item)" icon="mdi-eye" tooltip="Ver Detalle"></base-icon-btn>
											<base-icon-btn v-if="item.canCancel" color="red darken-1" @click="openInventoryMovementActionDialog(item, $Const.INVENTORY_MOVEMENT_STATUS.CANCELLED)" icon="mdi-cancel" tooltip="Anular"></base-icon-btn>
											<base-icon-btn v-if="item.statusId!==$Const.INVENTORY_MOVEMENT_STATUS.CANCELLED" color="green darken-1" @click="reportView(item)" icon="mdi-file-chart" tooltip="Ver Informe"></base-icon-btn>

											<base-icon-btn v-if="item.statusId===$Const.INVENTORY_MOVEMENT_STATUS.REGISTERED" color="cyan darken-1" @click="openInventoryMovementActionDialog(item, $Const.INVENTORY_MOVEMENT_STATUS.SHIPPED)" icon="mdi-truck-delivery" tooltip="Registrar envío"></base-icon-btn>
										</template>

										<template v-slot:item.status="{ item }">
											<template v-if="item.statusId===$Const.INVENTORY_MOVEMENT_STATUS.REGISTERED">
												<span class="blue--text">{{item.status}}</span>
											</template>
											<template v-else-if="item.statusId===$Const.INVENTORY_MOVEMENT_STATUS.SHIPPED">
												<span class="secondary--text">{{item.status}}</span>
											</template>
											<template v-else-if="item.statusId===$Const.INVENTORY_MOVEMENT_STATUS.DELIVERED">
												<span class="secondary--text">{{item.status}}</span>
											</template>
											<template v-else-if="item.statusId===$Const.INVENTORY_MOVEMENT_STATUS.APPROVED">
												<span class="success--text">{{item.status}}</span>
											</template>
											<template v-else>
												<span class="red--text">{{item.status}}</span>
											</template>
										</template>
									</base-data-table>
								</v-expansion-panel-content>
							</v-expansion-panel>

							<v-expansion-panel>
								<v-expansion-panel-header class="uppercase">Movimientos de entrada</v-expansion-panel-header>
								<v-expansion-panel-content>
									<base-data-table 
										v-model="sortBy"
										desc
										:headers="headers" 
										:items="assignedInventoryMovements" 
										hideNewItemButton
										@loadDataTable="toList(3)"
									>
										<template v-slot:item.options="{ item }">
											<base-icon-btn color="info darken-1" @click="viewItem(item)" icon="mdi-eye" tooltip="Ver Detalle"></base-icon-btn>
											<base-icon-btn v-if="item.statusId!==$Const.INVENTORY_MOVEMENT_STATUS.CANCELLED" color="green darken-1" @click="reportView(item)" icon="mdi-file-chart" tooltip="Ver Informe"></base-icon-btn>

											<base-icon-btn v-if="item.statusId===$Const.INVENTORY_MOVEMENT_STATUS.SHIPPED" color="secondary darken-1" @click="openInventoryMovementActionDialog(item, $Const.INVENTORY_MOVEMENT_STATUS.DELIVERED)" icon="mdi-store" tooltip="Registrar recepción"></base-icon-btn>
											<base-icon-btn v-else-if="item.statusId===$Const.INVENTORY_MOVEMENT_STATUS.DELIVERED" color="success" @click="openInventoryMovementActionDialog(item, $Const.INVENTORY_MOVEMENT_STATUS.APPROVED)" icon="mdi-check-bold" tooltip="Aprobar"></base-icon-btn>
										</template>

										<template v-slot:item.status="{ item }">
											<template v-if="item.statusId===$Const.INVENTORY_MOVEMENT_STATUS.REGISTERED">
												<span class="blue--text">{{item.status}}</span>
											</template>
											<template v-else-if="item.statusId===$Const.INVENTORY_MOVEMENT_STATUS.SHIPPED">
												<span class="secondary--text">{{item.status}}</span>
											</template>
											<template v-else-if="item.statusId===$Const.INVENTORY_MOVEMENT_STATUS.DELIVERED">
												<span class="secondary--text">{{item.status}}</span>
											</template>
											<template v-else-if="item.statusId===$Const.INVENTORY_MOVEMENT_STATUS.APPROVED">
												<span class="success--text">{{item.status}}</span>
											</template>
											<template v-else>
												<span class="red--text">{{item.status}}</span>
											</template>
										</template>
									</base-data-table>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>

						<v-dialog v-model="showInventoryMovementActionDialog" persistent scrollable max-width="800px">
							<base-form-simple ref="InventoryMovementActionForm"
								:formTitle="editedInventoryMovement.title"
								:formLoading="loadingInventoryMovementActionForm"
								@cancelButton="closeInventoryMovementActionDialog"
								@saveButton="saveInventoryMovementAction"
							>
								<template v-slot:formContent>
									<v-container class="no-padding" fluid>
										<v-row>
											<v-col cols="12" sm="3" md="3">
												<base-text-field v-model="editedInventoryMovement.id" label="Número de movimiento" readonly></base-text-field>
											</v-col>
											<v-col v-if="editedInventoryMovement.nextStatusId === $Const.INVENTORY_MOVEMENT_STATUS.CANCELLED" cols="12" sm="12" md="12">
												<base-textarea outlined v-model="editedInventoryMovement.reason" label="Motivo" counter maxlength="250" 
												:rules="[commonValRules.required, validationRules.reasonRules.counter]" required></base-textarea>
											</v-col>
											<v-col v-else cols="12" sm="12" md="12">
												<base-textarea outlined v-model="editedInventoryMovement.reason" label="Comentarios adicionales" counter maxlength="250" 
												:rules="[validationRules.commentsRules.counter]"></base-textarea>
											</v-col>
										</v-row>
									</v-container>
								</template>
							</base-form-simple>
						</v-dialog>

					</v-window-item>
					<v-window-item :value="2">
						<v-dialog v-model="showProductFinder" persistent scrollable max-width="1000px">
							<base-product-finder mode="Inventory" :selectedBranchOfficeId="editedItem.branchOfficeId" :inventoryMovementTypeId="editedItem.inventoryMovementTypeId" @cancelButton="closeProductFinder" @addProduct="addDetail" @removeProduct="removeDetailFromFinder" :productsAdded="details.map(x => x.productId)"/>
						</v-dialog>

						<base-form-simple ref="InventoryMovementForm"
							:formTitle="inventoryMovementFormTitle"
							:formLoading="formLoading"
							:hideSaveButton="hideSaveButton"
							@cancelButton="close"
							@saveButton="save"
						>
							<template v-slot:formContent>
								<v-container class="no-padding" fluid>
									<v-row justify="center">
										<v-col v-if="showReason" cols="12">
											<v-alert
												text
												elevation="2"	
												color="red"
												transition="scale-transition"
											>
												<v-icon color="red" left>mdi-alert-circle</v-icon>
												<strong>El movimiento fue anulado por el siguiente motivo: </strong> {{editedItem.reason}}
											</v-alert>
										</v-col>

										<!-- Detalles del movimiento -->
										<v-col cols="12" sm="6" md="6">
											<base-form-container
												title="Datos del movimiento"
												titleClasses="grey lighten-3"
											>
												<template v-slot:content>
													<v-row align="center" justify="center">
														<v-col v-if="viewDetails" cols="12" sm="6" md="4" xlg="3">
															<base-text-field v-model="editedItem.id" label="Nro. de Movimiento" readonly>
															</base-text-field>
														</v-col>
														<v-col v-if="viewDetails" cols="12" sm="6" md="4" xlg="3">
															<base-text-field v-model="editedItem.dateTime" label="Fecha y hora" readonly>
															</base-text-field>
														</v-col>
														<v-col v-if="viewDetails" cols="12" sm="6" md="4" xlg="3">
															<base-text-field v-model="editedItem.status" label="Estado" readonly>
															</base-text-field>
														</v-col>

														<v-col cols="12" sm="6" md="4" xlg="3">
															<base-text-field v-if="viewDetails" v-model="editedItem.inventoryMovementType" label="Tipo" readonly>
															</base-text-field>

															<v-scale-transition v-else v-model="editSourceBranchOffice" mode="out-in">
																<base-select v-if="editSourceBranchOffice" v-model="editedItem.inventoryMovementTypeId" :items="inventoryMovementTypes" label="Tipo" @change="inventoryMovementTypeOnChange"
																:rules="[commonValRules.required]" required></base-select>
																<base-text-field v-else v-model="inventoryMovementTypeSelected" label="Tipo" disabled>
																</base-text-field>
															</v-scale-transition>
														</v-col>
														<v-col v-if="viewDetails" cols="12" sm="6" md="8" xlg="6">
															<base-text-field v-model="editedItem.user" label="Responsable" readonly>
															</base-text-field>
														</v-col>

														<v-expand-transition>
															<v-col v-if="!viewDetails && !!editedItem.inventoryMovementTypeId && editedItem.inventoryMovementTypeId != 1" cols="12">
																<v-alert dark
																	dense
																	elevation="2"	
																	color="secondary"
																	class="text-justify"
																	
																	icon="mdi-information"
																>
																	<p><b>¡IMPORTANTE!</b></p>
																	El registro del movimiento tendrá un efecto inmediato en su inventario y <strong>no podrá ser revertido</strong>, verifique bien los datos antes de presionar el botón <b>Guardar</b>
																</v-alert>
															</v-col>
														</v-expand-transition>
													</v-row>
												</template>
											</base-form-container>
										</v-col>

										<!-- Sucursales -->
										<v-col cols="12" sm="6" md="6">
											<base-form-container
												title="Sucursales"
												titleClasses="grey lighten-3"
											>
												<template v-slot:content>
													<v-row justify="center">
														<v-col cols="12" sm="6" md="6">
															<base-text-field v-if="viewDetails" v-model="editedItem.branchOffice" label="Origen" readonly>
															</base-text-field>
															<base-text-field v-else v-model="branchOfficeSelected" label="Origen" disabled>
															</base-text-field>
														</v-col>
														<v-col v-if="showTargetBranchOffice" cols="12" sm="6" md="6">
															<base-text-field v-if="viewDetails" v-model="editedItem.targetBranchOffice" label="Destino" readonly>
															</base-text-field>
															<base-select v-else v-model="editedItem.targetBranchOfficeId" :items="filteredBranchOfficesAvailable" label="Destino" 
															:rules="[commonValRules.required]" required></base-select>
														</v-col>
														<v-col v-if="!viewDetails" cols="12" sm="8">
															<v-scale-transition v-model="editSourceBranchOffice" mode="out-in">
																<base-btn v-if="editSourceBranchOffice" key="Disable" block dark color="primary" @click="checkAvailableInventoryProducts()" icon="mdi-list-status" btnText="Seleccionar productos"></base-btn>
																<base-btn v-else key="Enable" block dark color="secondary" @click="changeInitialMovementData()" icon="mdi-format-list-checks" btnText="Cambiar datos iniciales"></base-btn>
															</v-scale-transition>
														</v-col>
													</v-row>
												</template>
											</base-form-container>
										</v-col>

										<!-- Detalles de los productos -->
										<v-col v-if="!editSourceBranchOffice || viewDetails" cols="12">
											<base-form-container
												title="Productos"
												titleClasses="info darken-1 white--text"
											>
												<template v-slot:content>
													<v-row v-if="!viewDetails" dense align="center" justify="center">
														<v-col cols="9" sm="8" md="6" align="center">
															<v-tooltip v-if="$vuetify.breakpoint.mdAndUp" top>
																<template v-slot:activator="{ on }">
																	<base-text-field v-on="on" clearable @keyup.enter="fetchProduct()" v-model="productCode" label="Código Producto" counter maxlength="20" prepend-icon="mdi-cart"
																	:hint="productCodeHint"></base-text-field>
																</template>
																<span>Ingrese el código y presione la tecla 'Enter'</span>
															</v-tooltip>
															<template v-else>
																<base-text-field clearable v-model="productCode" label="Código Producto" counter maxlength="20"
																:hint="productCodeHint"></base-text-field>
															</template>
														</v-col>
														<v-col v-if="$vuetify.breakpoint.smAndDown" cols="3" sm="2" align="center">
															<base-icon-btn fabMode small btnColor="secondary" color="white" @click="fetchProduct()" icon="mdi-cart" tooltip="Agregar" :disabled="!productCodeIsValid"></base-icon-btn>
														</v-col>
														<v-col cols="12" sm="6" md="4" align="center">
															<base-btn color="primary" @click="openProductFinder()" icon="mdi-clipboard-text-search" btnText="Productos"></base-btn>
														</v-col>
													</v-row>
													
													<v-row align="center" justify="center">
														<v-col cols="12" align="center">
															<base-data-table ref="DtDetails"
																:headers="customDetailHeaders" 
																:items="details"
																sortBy="product"
																hideHeader
																:show-expand="showExpand"
																item-key="productId"
																elevation="0"
																showBorder
															>
																<template v-if="!viewDetails" v-slot:item.options="{ item }">
																	<base-icon-btn :size="25" min-elevation="0" color="red darken-1" @click="removeDetail(item)" icon="mdi-close" tooltip="Eliminar Producto"></base-icon-btn>
																	<base-icon-btn :size="25" min-elevation="0" color="secondary" @click="updateProduct(item)" icon="mdi-reload" tooltip="Actualizar Producto"></base-icon-btn>
																</template>

																<template v-slot:expanded-item="{ item }">
																	<base-stock-details-picker :ref="`StockForm${item.productId}`" :productId="item.productId" :stockFormId="item.stockFormId" :stockGroup="item.movementDetails" :detailMode="detailMode">
																	</base-stock-details-picker>
																</template>

																<template v-slot:item-data-table-expand="{ expand, isExpanded, item }">
																	<transition name="fade" mode="out-in">
																		<base-icon-btn v-if="isExpanded" :size="25" min-elevation="0" key="hide" @click="expand(closeStockFormDetails(isExpanded, item))" color="red darken-1" icon="mdi-eye-off" tooltip="Ocultar Detalles"></base-icon-btn>
																		<base-icon-btn v-else :size="25" min-elevation="0" key="show" @click="expand(!isExpanded)" color="success darken-1" icon="mdi-eye" tooltip="Ver Detalles"></base-icon-btn>
																	</transition>
																</template>

																<template v-slot:item.image="{ item }">
																	<div style="width:100px;">
																		<base-product-img v-model="item.image" :productId="item.productId"
																			class="my-2"
																			gradient="to bottom, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.07)"
																			aspect-ratio="1.5"
																			contain
																		>
																		</base-product-img>
																	</div>
																</template>

																<template v-slot:item.quantity="{ item }">
																	<base-text-field v-if="!viewDetails" type="number" class="centered-input custom-max-width" :value="getStockSum(item)" maxlength="6"
																	hint="Se actualiza desde los detalles"
																	prepend-inner-icon="mdi-cart-variant" :rules="[commonValRules.required, validationRules.quantityRules.counter, validationRules.quantityRules.valid]" required readonly></base-text-field>

																	<template v-else>
																		<v-icon small>mdi-cart-variant</v-icon> {{ item.quantity }}
																	</template>
																</template>

																<template v-slot:no-data>
																	<h3 class="red--text">*No hay productos agregados al detalle.</h3>
																</template>
															</base-data-table>
														</v-col>
													</v-row>
												</template>
											</base-form-container>
										</v-col>

										<!-- Datos finales -->
										<v-col v-if="!editSourceBranchOffice || viewDetails" cols="12" sm="6" order-sm="2" align="right">
											<base-form-container
												title="Datos finales"
												titleClasses="info darken-1 white--text"
											>
												<template v-slot:titleContent>
													<v-spacer></v-spacer>
													<v-badge color="red" overlap :content="calculateTotalProductsForBadge" :value="calculateTotalProductsForBadge">
														<v-icon dark>mdi-cart-variant</v-icon>
													</v-badge>
												</template>

												<template v-slot:content>
													<v-row align="center" justify="center" no-gutters>
														<v-col v-if="!viewDetails" cols="6" sm="6" align="center">
															<v-checkbox
																v-model="editedItem.generateVoucher"
																label="Generar Comprobante"
																color="info"
																class="custom-max-width"
															></v-checkbox>
														</v-col>
														<v-col cols="6" sm="6" align="center">
															<h3 class="mt-6 mt-sm-0">
																<strong>Productos en Total</strong>
																<p class="mt-2 mb-0">{{calculateTotalProducts}}</p>
															</h3>
														</v-col>
														<v-col cols="12" class="pt-4">
															<base-textarea outlined v-model="editedItem.comments" label="Comentarios" :counter="!viewDetails" maxlength="250" 
															:rules="[validationRules.commentsRules.counter]" :readonly="viewDetails"></base-textarea>
														</v-col>
													</v-row>
												</template>
											</base-form-container>
										</v-col>
									</v-row>
								</v-container>
							</template>
						</base-form-simple>
					</v-window-item>
				</v-window>
			</v-col>
		</v-row>

		<base-pdf-viewer :show="showPdfViewer" :title="reportFile.title" :src="reportFile.fileContent" :fileName="reportFile.fileName" @close="closeViewer()"/>
	</v-container>
</template>

<script>
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	mixins: [MainExternalData],

	data() {
		return {
			title:'Movimientos',
			step: 1,
			sortBy: 'dateTime',
			panel: [0, 1],
			headers: [
				{ text: "Opciones", value: "options", sortable: false },
				{ text: "Nro. Movimiento", value: "id" },
        { text: "Usuario", value: "userFullName" },
				{ text: "Tipo", value: "inventoryMovementType" },
				{ text: "Origen", value: "branchOffice" },
				{ text: "Destino", value: "targetBranchOffice" },
				{ text: "Fecha", value: "dateTime" },
        { text: "Estado", value: "status" }
			],
			inventoryMovements: [],
			assignedInventoryMovements: [],
			detailHeaders: [
				{ text: "Opciones", value: "options", sortable: false },
				{ text: "Imagen", value: "image", sortable: false },
				{ text: "Código", value: "customCode", sortable: false },
				{ text: "Producto", value: "product", sortable: false },
				{ text: "Stock", value: "stock", sortable: false },
        { text: "Cantidad", value: "quantity", sortable: false },
				{ text: 'Detalles', value: 'data-table-expand' },
			],
			details: [],
			showExpand: true,
			inventoryMovementTypes:[],
			editedItem: {
				id: '',
				inventoryMovementTypeId:'',
				branchOfficeId:'',
				targetBranchOfficeId: '',
				generateVoucher: false,
				comments: '',

				user: '',
				branchOffice:'',
				inventoryMovementType:'',
				targetBranchOffice: '',
				dateTime: '',
				statusId: 0,
				status: '',
				reason: '',
      },
      defaultItem: {
       	id: '',
				inventoryMovementTypeId:'',
				branchOfficeId:'',
				targetBranchOfficeId: '',
				generateVoucher: false,
				comments: '',

				user: '',
				branchOffice:'',
				inventoryMovementType:'',
				targetBranchOffice: '',
				dateTime: '',
				statusId: 0,
				status: '',
				reason: '',
			},
			branchOfficesAvailable: [],
			editSourceBranchOffice: true,
			formLoading: false,
			viewDetails:false,
			//PRODUCT
			productCode: '',
			productCodeIsValid:false,
			showProductFinder:false,
			//INVENTORY MOVEMENT ACTION
			editedInventoryMovement: {
				title: '',
				id: '',
				reason: '',
				nextStatusId: 0
      },
      defaultInventoryMovement: {
				title: '',
       	id: '',
				reason: '',
				nextStatusId: 0
			},
			showInventoryMovementActionDialog: false,
			loadingInventoryMovementActionForm: false,
			//VALIDATIONS
      validationRules: {
				productCodeRules: {
					counter: value => (!!value && value.length >= 4 && value.length <= 20) || 'No debe tener más de 20 caracteres, ni menos de 4 caracteres.',
				},

				quantityRules: {
					counter: value => (value != null && value.toString().length <= 6) || 'No debe tener más de 6 caracteres.',
          valid: value => (parseInt(value) > 0) || 'No puede ser menor a 1.',
				},
				commentsRules: {
          counter: value => (value.length <= 250) || 'No debe tener más de 250 caracteres.',
				},

				reasonRules: {
					counter: value => (!!value && value.length >= 10 && value.length <= 250) || 'No debe tener más de 250 caracteres, ni menos de 10 caracteres.',
				},
			},
			//PDF comprobante
			showPdfViewer: false,
			reportFile:{
				title: '',
				fileName:'',
				fileContent:''
			},
			defaultReportFile:{
				title: '',
				fileName:'',
				fileContent:''
			},
		}
	},

	computed: {
		inventoryMovementFormTitle() {
			return this.viewDetails ? 'Movimiento' : 'Nuevo movimiento';
		},

		detailMode() {
			if (this.viewDetails) {
				return 'ShowStockMovement';
			} else if (this.editedItem.inventoryMovementTypeId == this.$Const.INVENTORY_MOVEMENT_TYPE.ADJUSTMENT) {
				return 'IncreaseStock';
			} else {
				return 'DistributeStock';
			}
		},

		hideSaveButton(){
			if (this.viewDetails) {
				return true;
			} else {
				return this.details.length <= 0;
			}
		},

		inventoryMovementTypeSelected(){
			if (this.editedItem.inventoryMovementTypeId == '') {
				return ''
			}
			return this.inventoryMovementTypes.find(x => x.value === this.editedItem.inventoryMovementTypeId).text
		},

		branchOfficeSelected(){
			this.editedItem.branchOfficeId = this.companyData.branchOfficeId;
			return this.branchOfficesAvailable.find(x => x.value === this.editedItem.branchOfficeId).text
		},

		filteredBranchOfficesAvailable() {
			this.editedItem.targetBranchOfficeId = '';
			if (this.editedItem.branchOfficeId == '') {
				return []
			}
			return this.branchOfficesAvailable.filter(f => f.value != this.editedItem.branchOfficeId);
		},

		showMainReport(){
			if (this.step === 2 || this.inventoryMovements.length<=0) {
				return false;	
			}
			return true;
		},

		customDetailHeaders(){
			var excludes = [];
			if (this.viewDetails) {
				excludes.push('options');
				excludes.push('stock');
			}

			if (excludes.length <= 0) {
				return this.detailHeaders;
			}
			return this.detailHeaders.filter(x => !excludes.includes(x.value));
		},

		productCodeHint(){
			var result = this.validationRules.productCodeRules.counter(this.productCode);
			if (result == true) {
				this.productCodeIsValid = true;
				return '';
			}
			else {
				this.productCodeIsValid = false;
				return result;
			}
		},

		calculateTotalProducts(){
			var result=0;
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				result = result + parseInt(element.quantity, 10);
			}
			return result > 0 ? result : 0;
		},

		calculateTotalProductsForBadge(){
			var result=0;
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				result = result + parseInt(element.quantity, 10);
			}
			return result > 0 ? (result > 99 ? result = '+99' : result) : 0;
		},
		
		showReason(){
			var result = this.viewDetails && this.editedItem.statusId===this.$Const.INVENTORY_MOVEMENT_STATUS.CANCELLED;
			return result;
		},

		showTargetBranchOffice(){
			if (this.viewDetails && this.editedItem.inventoryMovementTypeId == this.$Const.INVENTORY_MOVEMENT_TYPE.DISTRIBUTION) {
				return true;
			}
			if (this.editedItem.inventoryMovementTypeId == this.$Const.INVENTORY_MOVEMENT_TYPE.DISTRIBUTION) {
				return true;
			}
			return false;
		},
	},

	mounted() {
		this.listOfBranchOffices();
		this.listOfInventoryMovementTypes();
		this.toList(1);
  },

	methods: {
		async toList(mode){
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.companyData.branchOfficeId,
					'mode': mode
				};
				switch (mode) {
					case 1:
						me.inventoryMovements = [];
						me.assignedInventoryMovements = [];
						break;
					case 2:
						me.inventoryMovements = [];
						break;
					case 3:
						me.assignedInventoryMovements = [];
						break;
				}
				await me.getObjectResponse('api/InventoryMovement/GetAllByBranchOffice', request).then(data => {
					if (data === undefined) {
						 return;
					}
					switch (mode) {
						case 1:
							me.inventoryMovements = data.inventoryMovements;
							me.assignedInventoryMovements = data.assignedInventoryMovements;
							break;
						case 2:
							me.inventoryMovements = data.inventoryMovements;
							break;
						case 3:
							me.assignedInventoryMovements = data.assignedInventoryMovements;
							break;
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		async listOfBranchOffices(){
      let me=this;
			var branchOfficesArray=[];
			await me.getObjectResponse('api/BranchOffice/Select', me.baseRequest).then(data => {
				if (data === undefined) {
					return;
				}
				branchOfficesArray = data.branchOffices;
				branchOfficesArray.map(function(x){
					me.branchOfficesAvailable.push({text:x.visualName, value:x.id});
				});
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
		},

		async listOfInventoryMovementTypes(){
      let me=this;
			let request={
				'companyId': me.baseRequest.companyId,
				'userId': me.baseRequest.userId,
				'branchOfficeId': me.companyData.branchOfficeId
			};
			var inventoryMovementTypesArray=[];
			await me.getObjectResponse('api/Parameter/SelectAvailableInventoryTypesByBranchOffice', request).then(data => {
				if (data === undefined) {
					return;
				}
				inventoryMovementTypesArray = data.inventoryMovementTypes; 
				inventoryMovementTypesArray.map(function(x){
					me.inventoryMovementTypes.push({text:x.visualName, value:x.id});
				});
			}).catch(function(error){
        me.$swal(me.swalConfig.errorTitle, error.message, 'error');
      });
		},

		async fetchProduct(){
			let me=this;
			if (!me.productCodeIsValid) {
				return;
			}
			if (me.details.some(x => x.customCode == me.productCode)) {
				me.$swal(me.swalConfig.errorTitle, 'El producto ya se encuentra agregado.', 'error');
				return;
			}
			try {
				me.SHOW_LOADING()
				me.formLoading=true;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.editedItem.branchOfficeId,
					'code': me.productCode,
					'inventoryMovementTypeId': me.editedItem.inventoryMovementTypeId
				};
				await me.getObjectResponse('api/ProductInventory/GetProductInventoryByCode', request).then(data => {
					if (data !== undefined) {
						me.addDetail(data.product);
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading=false;
      }
		},

		async updateProduct(item){
			let me=this;
			try {
				me.SHOW_LOADING()
				me.formLoading=true;
				const lastImage = item.image;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.editedItem.branchOfficeId,
					'code': item.customCode,
					'inventoryMovementTypeId': me.editedItem.inventoryMovementTypeId
				};
				await me.getObjectResponse('api/ProductInventory/GetProductInventoryByCode', request).then(data => {
					if (data !== undefined) {
						me.updateDetail(data.product, lastImage);
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading=false;
      }
		},

		inventoryMovementTypeOnChange(){
			this.editedItem.branchOfficeId = '';
		},

		openProductFinder(){
			this.showProductFinder=true;
    },

    closeProductFinder() {
			this.showProductFinder=false;
			this.productCode='';
    },

		addDetail(data = []){
			if (this.details.some(x => x.productId == data['id'])) {
				this.$swal(this.swalConfig.errorTitle, 'El producto ya se encuentra agregado.', 'error');
				return;
			}
			let stockFormId = parseInt(data['stockFormId'], 10)
			let movementDetails = this.parseStockDetails(data['movementDetails'], stockFormId, false)
			this.details.push({
				productId: data['id'],
				image: data['image'], 
				customCode: data['customCode'], 
				product: data['visualName'], 
				stock: parseInt(data['stock'], 10),
				quantity: 0,

				stockFormId: stockFormId,
				movementDetails: movementDetails
			});
			this.productCode='';
		},

		updateDetail(data = [], image) {
			let me=this;
			let stockFormId = parseInt(data['stockFormId'], 10)
			let movementDetails = me.parseStockDetails(data['movementDetails'], stockFormId, false)
			const newItem = {
				productId: data['id'], 
				image: image,
				customCode: data['customCode'], 
				product: data['visualName'], 
				stock: parseInt(data['stock'], 10),
				quantity: 0, 

				stockFormId: stockFormId,
				movementDetails: movementDetails
			};

			const index = me.details.findIndex((x) => x.productId === newItem.productId);
			me.details.splice(index, 1, newItem);
		},

		parseStockDetails(details, stockFormId, serialize) {
			var result = []
			let cloneDetails = details.slice();
			if (serialize) {
				cloneDetails.map((x) => {
					let stockDetailsAvailable = x.stockDetails.filter(f => f.stock !== null && f.stock && parseInt(f.stock) > 0).map(x => x);
					if (stockDetailsAvailable.length <= 0) {
						return;
					}
					stockDetailsAvailable = stockDetailsAvailable.map((x) => {
						const res  = Object.fromEntries(Object.entries(x).filter(e=>e[0]!=='originalStock'));
						return res;
					});
					let stockValues = stockDetailsAvailable.map(x => parseInt(x.stock)).reduce((a,b) => a+b);
					if (stockFormId === this.$Const.STOCK_FORM.GENERAL) {
						result.push({ 
							purchaseId:x.purchaseId,
							stock:stockValues,
							stockDetails:null
						});
					} else {
						let stockDetails = JSON.stringify(stockDetailsAvailable);
						result.push({ 
							purchaseId:x.purchaseId,
							stock:stockValues,
							stockDetails:stockDetails
						});
					}
				});
			} else {
				cloneDetails.map((x) => {
					let stockDetails = []
					if (stockFormId === this.$Const.STOCK_FORM.GENERAL) {
						stockDetails.push({
							id:1,
							stock:x.stock
						});
					} else {
						stockDetails = JSON.parse(x.stockDetails);
					}

					for (let i = 0; i < stockDetails.length; i++) {
						const element = stockDetails[i];
						if (!this.viewDetails) {
							element.originalStock = element.stock
							element.stock = 0
						}
					}

					result.push({ 
						purchaseId:x.purchaseId,
						stock:x.stock,
						stockDetails:stockDetails
					});
				});
			}
			return result;
		},

		getStockSum(item){
			if (this.viewDetails) {
					return item.quantity;
			}
			let total = 0;
			for (let i = 0; i < item.movementDetails.length; i++) {
				const element = item.movementDetails[i];
				var stockArray = element.stockDetails.filter(f => f.stock !== null && f.stock).map(x => parseInt(x.stock))
				if (stockArray.length > 0) {
					const sum = stockArray.reduce((a,b) => a+b)
					total += sum	
				}
			}
			item.quantity = total;
			return total;
		},

		removeDetail(item){
			const index = this.details.indexOf(item);
      if (index !== -1) {
				this.details.splice(index, 1);
			}
		},

		removeDetailFromFinder(data = []){
			const index = this.details.findIndex(x => x.productId == data['id']);
      if (index !== -1) {
				this.details.splice(index, 1);
			}
		},

		setDetails(details){
			var result = [];
			for (let i = 0; i < details.length; i++) {
				const element = details[i];
				var stockFormId = parseInt(element.stockFormId, 10)
				var movementDetails = JSON.parse(element.movementDetails)
				var movementDetailsResult = this.parseStockDetails(movementDetails, stockFormId, false)
				
				result.push({
					id: element.id,
					productId: element.productId,
					image: null,
					customCode: element.customCode,
					product: element.product,
					quantity: parseInt(element.quantity, 10), 

					stockFormId: stockFormId,
					movementDetails: movementDetailsResult,
				});
			}
			return result;
		},

		getDetails(){
			var result = [];
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				var stockFormId = parseInt(element.stockFormId, 10)
				var movementDetails = this.parseStockDetails(element.movementDetails, stockFormId, true)
				result.push({
					productId: element.productId,
					movementDetails: JSON.stringify(movementDetails),
				});
			}
			return result;
		},

		closeStockFormDetails(isExpanded, item) {
			if (this.viewDetails) {
				return !isExpanded;
			}

			var form = this.$refs[`StockForm${item.productId}`];
			if (!form.validateStockFormDetails()) {
				return isExpanded;
			}
			return !isExpanded;
		},

		validateAllStockFormDetails(){
			for (let i = 0; i < this.details.length; i++) {
				const element = this.details[i];
				var form = this.$refs[`StockForm${element.productId}`];
				if (form === undefined) {
					continue;	
				}
				if (!form.validateStockFormDetails()) {
					return false;
				}
			}
			return true;
		},

		async viewItem(item) {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': item.id,
				};
				await me.getObjectResponse('api/InventoryMovement/GetById', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.viewDetails=true;
					me.editedItem = Object.assign({}, data.inventoryMovement);
					me.details = me.setDetails(data.inventoryMovement.details);
					me.step++;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },

    close() {
			this.step=1;
			this.clean();
    },
    
    clean(){
			if (this.$refs.DtDetails) {
				this.$refs.DtDetails.collapseAll();	
			}
			this.$refs.InventoryMovementForm.reset();
      this.editedItem = Object.assign({}, this.defaultItem);
			this.productCode="";
			this.details = [];
			this.viewDetails=false;
			this.editSourceBranchOffice = true;
    },

    async save() {
			let me=this;

			if (me.details.length <= 0) {
				return;
			}

			if (!me.validateAllStockFormDetails()) {
				return;
			}

			switch (me.editedItem.inventoryMovementTypeId) {
				case 1:
					break;
				case 2:
					if (!await me.showConfirmationDialog('Estás a punto de registrar productos con stock dañado, esta acción no será revertida.', 'Sí, registrar el stock dañado!')) {
						return;
					}
					break;
				case 3:
					if (!await me.showConfirmationDialog('Estás a punto de registrar un ajuste en el inventario, esta acción no será revertida.', 'Sí, registrar el ajuste!')) {
						return;
					}
					break;
			}

			try {
        me.SHOW_LOADING()
        me.formLoading=true;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.editedItem.branchOfficeId,
					'targetBranchOfficeId': me.editedItem.targetBranchOfficeId,
					'inventoryMovementTypeId': parseInt(me.editedItem.inventoryMovementTypeId, 10),
					'comments': me.editedItem.comments,
					'generateVoucher': me.editedItem.generateVoucher,

					'details': me.getDetails()
				};
				await me.getObjectResponse('api/InventoryMovement/Create', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.addOrUpdateListItem(data.inventoryMovement, 1);
					if (me.editedItem.generateVoucher && data.fileContent != null) {
						me.reportFile.title = `Movimiento ${data.inventoryMovement.id}`;
						me.reportFile.fileName = data.fileName;
						me.reportFile.fileContent = data.fileContent;
						me.showPdfViewer=true;
					}	else {
						me.close();
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
        me.formLoading=false;
      }
		},
		
		addOrUpdateListItem(item, list){
			let me=this;
			if (list === 1) {
				if (!!me.inventoryMovements.find((x) => x.id === item.id)) {
					const index = me.inventoryMovements.findIndex((x) => x.id === item.id)
					me.inventoryMovements.splice(index, 1, item)
				} else {
					me.inventoryMovements.push(item);
				}
			}
			else if (list === 2) {
				if (!!me.assignedInventoryMovements.find((x) => x.id === item.id)) {
					const index = me.assignedInventoryMovements.findIndex((x) => x.id === item.id)
					me.assignedInventoryMovements.splice(index, 1, item)
				} else {
					me.assignedInventoryMovements.push(item);
				}
			}
		},

		async checkAvailableInventoryProducts(){
			let me=this;
			if (!me.$refs.InventoryMovementForm.validate()) {
				return;
			}
			try {
        me.SHOW_LOADING()
      	me.formLoading=true;
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.editedItem.branchOfficeId,
					'inventoryMovementTypeId': me.editedItem.inventoryMovementTypeId
				};
				await me.getBaseResponse('api/ProductInventory/CheckIfBranchOfficeHasProductsAvailable', request).then(data => {
					if (data) {
            me.editSourceBranchOffice = false;
          }
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.formLoading=false;
      }
		},

		async changeInitialMovementData(){
			if (this.details.length > 0) {
				if (!await this.showConfirmationDialog('Si cambias los datos iniciales se perderán los productos agregados al detalle.', 'Sí, cambiar datos iniciales!')) {
					return;
				}
			}
			this.productCode="";
			this.details = [];
			this.editSourceBranchOffice = true;
		},

		
		openInventoryMovementActionDialog(item, nextStatusId){
			let me = this;
			me.editedInventoryMovement.id = item.id;
			me.editedInventoryMovement.nextStatusId = nextStatusId;
			switch (nextStatusId) {
				case me.$Const.INVENTORY_MOVEMENT_STATUS.CANCELLED:
					me.editedInventoryMovement.title = 'Anular el movimiento';
					break;
				case me.$Const.INVENTORY_MOVEMENT_STATUS.SHIPPED:
					me.editedInventoryMovement.title = 'Registrar el envío de productos';
					break;
				case me.$Const.INVENTORY_MOVEMENT_STATUS.DELIVERED:
					me.editedInventoryMovement.title = 'Registrar la recepción de productos';
					break;
				case me.$Const.INVENTORY_MOVEMENT_STATUS.APPROVED:
					me.editedInventoryMovement.title = 'Aprobar el movimiento';
					break;
			}
			me.showInventoryMovementActionDialog = true;
		},

		closeInventoryMovementActionDialog(){
			this.showInventoryMovementActionDialog = false;
			this.$refs.InventoryMovementActionForm.reset();
      this.editedInventoryMovement = Object.assign({}, this.defaultInventoryMovement);
		},

		async saveInventoryMovementAction(){
			let me=this;
			try {
				me.SHOW_LOADING()
				me.loadingInventoryMovementActionForm = true;
				switch (me.editedInventoryMovement.nextStatusId) {
					case me.$Const.INVENTORY_MOVEMENT_STATUS.CANCELLED:
						await me.cancel();
						break;
					case me.$Const.INVENTORY_MOVEMENT_STATUS.SHIPPED:
						await me.shipping();
						break;
					case me.$Const.INVENTORY_MOVEMENT_STATUS.DELIVERED:
						await me.delivered();
						break;
					case me.$Const.INVENTORY_MOVEMENT_STATUS.APPROVED:
						await me.approve();
						break;
				}
			} catch (error) {
        me.catchError(error)
      } finally {
				me.HIDE_LOADING()
				me.loadingInventoryMovementActionForm = false;
      }
		},
		
		async cancel(){
			let me=this;
			if (!await me.showConfirmationDialog('Estás a punto de anular el movimiento del inventario, esta acción no será revertida.', 'Sí, anular el movimiento!')) {
				me.closeInventoryMovementActionDialog();
				return;
			}

			let request={
				'companyId': me.baseRequest.companyId,
				'userId': me.baseRequest.userId,
				'id': me.editedInventoryMovement.id,
				'reason': me.editedInventoryMovement.reason,
			};
			await me.getObjectResponse('api/InventoryMovement/Cancel', request).then(data => {
				if (data === undefined) {
					return;
				}
				me.addOrUpdateListItem(data.inventoryMovement, 1);
				me.closeInventoryMovementActionDialog();
			}).catch(function(error){
				me.$swal(me.swalConfig.errorTitle, error.message, 'error');
			});
		},

		async shipping() {
			let me=this;
			if (!await me.showConfirmationDialog('Estás a punto de registrar el envío de los productos a la sucursal de destino, esta acción no será revertida.', 'Sí, registrar el envío!')) {
				me.closeInventoryMovementActionDialog();
				return;
			}

			let request={
				'companyId': me.baseRequest.companyId,
				'userId': me.baseRequest.userId,
				'id': me.editedInventoryMovement.id,
				'comments': me.editedInventoryMovement.reason,
			};
			await me.getObjectResponse('api/InventoryMovement/Shipping', request).then(data => {
				if (data === undefined) {
					return;
				}
				me.addOrUpdateListItem(data.inventoryMovement, 1);
				me.closeInventoryMovementActionDialog();
			}).catch(function(error){
				me.$swal(me.swalConfig.errorTitle, error.message, 'error');
			});
		},

		async delivered() {
			let me=this;
			if (!await me.showConfirmationDialog('Estás a punto de registrar que recibiste los productos enviados desde la sucursal de origen, esta acción no será revertida.', 'Sí, recibí los productos!')) {
				me.closeInventoryMovementActionDialog();
				return;
			}

			let request={
				'companyId': me.baseRequest.companyId,
				'userId': me.baseRequest.userId,
				'id': me.editedInventoryMovement.id,
				'comments': me.editedInventoryMovement.reason,
			};
			await me.getObjectResponse('api/InventoryMovement/Delivered', request).then(data => {
				if (data === undefined) {
					return;
				}
				me.addOrUpdateListItem(data.inventoryMovement, 2);
				me.closeInventoryMovementActionDialog();
			}).catch(function(error){
				me.$swal(me.swalConfig.errorTitle, error.message, 'error');
			});
		},

		async approve() {
			let me=this;
			if (!await me.showConfirmationDialog('Estás a punto de aprobar el movimiento del inventario, el detalle de productos sera habilitado para las ventas y movimientos posteriores, esta acción no será revertida.', 'Sí, aprobar el movimiento!')) {
				me.closeInventoryMovementActionDialog();
				return;
			}

			let request={
				'companyId': me.baseRequest.companyId,
				'userId': me.baseRequest.userId,
				'id': me.editedInventoryMovement.id,
				'comments': me.editedInventoryMovement.reason,
			};
			await me.getObjectResponse('api/InventoryMovement/Approve', request).then(data => {
				if (data === undefined) {
					return;
				}
				me.addOrUpdateListItem(data.inventoryMovement, 2);
				me.closeInventoryMovementActionDialog();
			}).catch(function(error){
				me.$swal(me.swalConfig.errorTitle, error.message, 'error');
			});
		},

		goBack(){
      this.$emit('goBack');
    },
		
		async mainReportView() {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'branchOfficeId': me.companyData.branchOfficeId
				};
				await me.getObjectResponse('api/InventoryMovement/GetMainReportViewByBranchOffice', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.reportFile = Object.assign({}, data);
					me.reportFile.title = '100 Últimos movimientos';
					me.showPdfViewer=true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},

		async reportView(item) {
			let me=this;
			try {
        me.SHOW_LOADING()
				let request={
					'companyId': me.baseRequest.companyId,
					'userId': me.baseRequest.userId,
					'id': item.id,
				};
				await me.getObjectResponse('api/InventoryMovement/GetReportView', request).then(data => {
					if (data === undefined) {
						return;
					}
					me.reportFile = Object.assign({}, data);
					me.reportFile.title = `Movimiento ${item.id}`;
					me.showPdfViewer=true;
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
		},
		
		closeViewer() {
			this.reportFile = Object.assign({}, this.defaultReportFile);
			this.showPdfViewer = false;
			if (this.step===2) {
				this.close();
			}
		},
	},
}
</script>